/* Extra Small Screens */
@media screen and (min-width: 0px) and (max-width: 800px) {

    .find-destination-section > div > .slider {
        width: 250px !important;
        margin-top: 10px;
    }

    .slider-tick-label {
        font-size: 10px;
    }

    .text-width {
        width: 280px;
    }

    .create-trip-button-group .btn {
        font-size: 12px;
        height: 30px;
    }

    .trip-savvi-instructions {
        font-size: 0.9em;
    }

    .flight-search-modal h4,.accommodation-search-modal h4 {
        font-size: 12px;
    }

    .flight-search-modal h5,.accommodation-search-modal h5 {
        font-size: 10px;
    }

    .modal-subtitle {
        font-size: 11px;
    }

    .hotel-display {
        width: 200px;
        background-size: 200px;
        height: 45px;
    }

    .destination-suggestion-small {
        width: 280px;
        background-size: 280px;
    }

    .destination-suggestion {
        width: 320px;
        background-size: 320px;
    }

    .destination-suggestion-planning {
        width: 310px;
        background-size: 310px;
    }

    .planning {
        width: 280px !important;
        background-size: 280px !important;
    }

    .destination-suggestion > div {
        padding: 5px;
    }

    .destination-suggestion-middle {
        min-height: 70px;
    }

    .destination-suggestion-title > span {
        font-size: 14px;
    }

    .destination-suggestion-info .btn-sm {
        font-size: 10px;
    }

    .destination-suggestion-text {
        font-size: 10px;
    }

    .itinerary-header {
        padding: 5px 10px;
    }

    .itinerary-header > .total-cost {
        font-size: 12px;
    }

    .itinerary-header > p {
        font-size: 10px;
    }

    .flight-outbound-details > span {
        font-size: 10px;
        padding: 3px 5px;
    }

    .itinerary-date {
        padding: 5px;
    }

    .flight-price {
        font-size: 10px;
    }

    .flight-airline {
        font-size: .55em;
    }

    .flight-picker-times-location > span {
        font-size: .65em;
        padding: 2px;
    }

    .flight-search-modal > .modal-dialog, .accommodation-search-modal > .modal-dialog {
        width: 325px;
    }

    .flight-picker-price {
        font-size: .6em;
    }

    .flight-icon {
        font-size: .5em;
    }

    .modal-footer {
        padding: 2px
    }

    .pagination {
        zoom: 0.8;
    }

    .btn {
        padding: 3px 8px;
    }

    .btn-sm {
        font-size: 10px;
        padding: 3px 8px;
    }

    .panel-title {
        font-size: 12px;
    }

    p {
        margin: 0 0 5px;
    }

    h4 {
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    #message-button, #flight-suggestion-button, #accommodation-suggestion-button, #extras-suggestion-button, #new-destination-suggestion-button, #new-poll {
        zoom: 1.5;
    }

    /* Hide certain components for mobile */
    .add-to-itinerary {
        display: none;
    }

    .mobile-hide {
        display: none;
    }

    .comments-display {
        zoom: 0.9;
    }

    .existing-comments {
        font-size: 14px !important;
    }

    .poll-option-message-component {
        width: 300px;
    }

    .check-box-options .btn {
        padding: 10px;
    }

    .notification {
        padding: 4px;
        border: 1px solid #334b62;
    }

    .notification a {
        font-size: 1.3rem;
        width: 100%;
    }

    #nav-item-notifications .dropdown-menu{
        max-height: 300px;
        overflow-y: auto;
    }

    #notification-dropdown {
        padding: 0 20px;
        text-align: right;
    }

    .navbar-nav {
        margin: 0 -15px;
    }

    .welcome-instruction-panel-body p  {
        font-size: 0.9rem;
        line-height: 1.7rem;
    }

}