/** Bootstrap classes **/
[class*="col-"] {
    padding: 0;
}

::-webkit-scrollbar {
    height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #90bbf1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #476f88;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

html {
    padding: 0;
    margin: 0;
}

body {
    background-color: rgb(90, 133, 180);
}

hr {
    margin-top: 5px;
    margin-bottom: 3px;
}

input[type=checkbox] {
    height: 15px;
    width: 15px;
    top: -1px;
}

h5 {
    margin: 0;
}

.modal-subtitle {
    font-size: 14px;
}

.help-block {
    font-size: 12px;
    text-align: left;
    color: black;
}

.slider-disabled .slider-selection.tick-slider-selection {
    background: rgb(169, 169, 169);
}

.slider-disabled .slider-tick.in-selection {
    background: rgb(168, 168, 168);
}

.modal-dialog {
    position: absolute;
    top: 45%;
    left: 47%;
    transform: translate(-50%, -50%) !important;
}

.modal-title {
    text-align: center;
}

.modal-footer {
    text-align: center;
}

.flight-search-modal > .modal-dialog {
    width: 750px;
}

.pagination {
    margin: 5px 0;
}

.modal-body {
    min-height: 100px;
    min-width: 200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.error-display-bar {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
}

.info-label {
    background-color: rgba(0, 0, 0, 0.4);
    margin: 5px;
    padding: 5px;
    border-radius: 3px;
}

.info-label span {
    color: white;
}

.info-label .btn {
    margin: 5px;
}

.error-display-bar-title {
    font-size: 22px;
}

.error-alert {
    padding: 10px;
    margin: 0;
    text-align: center;
}

.block-style {
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
    max-width: 700px;
    margin: 10px auto;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.planning-itinerary {
    margin: 5px 15px;
}

.block-style-planning-destination {
    margin: 0 auto;
    padding: 0;
}

.skyscanner span {
    font-size: 18px;
}

label.bpk-radio {
    font-size: 16px !important;
    vertical-align: middle;
}

.input-big-button-holder {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.big-button {
    width: 100%;
}

.middle-text {
    vertical-align: middle;
}

.row {
    margin: 0;
}

.panel {
    border: 0;
    margin-bottom: 0;
}

.panel-body {
    padding: 0;
}

.panel-title {
    font-size: 14px;
}

.navbar {
    margin-bottom: 0;
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 7px;
    font-size: 18px;
    line-height: 20px;
}

.slider-tick-label {
    color: white;
}

.form-control {
    background-color: #dde6f8 !important;
    border: 1px solid white;
    margin-top: 2px;
    margin-bottom: 2px;
}

.message-bottom-panel {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
}

.comment-holder {
    margin: 0 auto;
    width: 100%;
}

.comments-panel-body {
    background-color: #1c2533;
}

.comments-display {
    background-color: rgb(169, 215, 255);
    margin: 0 auto;
    border-radius: 5px;
    overflow-y: scroll;
    max-width: 1000px;
}

.comments-display-filter {
    background: rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    max-width: 1000px;
}

.comments-footer, .panel-heading {
    background-color: rgba(54, 72, 97, 0.99) !important;
    border-top: 5px solid rgba(0, 0, 0, 0.4) !important;
    border-bottom: 0;
}

.trip-details-panel {
    width: 100%;
    padding: 0 14px;
}

.trip-details-panel-header {
    background-color: rgba(54, 72, 97, 0.99) !important;
    border-bottom: 0;
}

.trip-details-panel-header span {
    font-size: 18px;
    color: white;
}

.trip-details-panel-body {
    background-color: #1c2533;
    padding: 10px;
}

.welcome-instruction-panel-body {
    background-color: #1c2533;
    height: auto;
}

.welcome-instruction-panel-body p {
    font-size: 1.4rem;
}

.trip-savvi-instructions .panel-footer {
    padding: 0;
    border-top: 0;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 5px;
    padding-top: 0;
}

.trip-creator {
    max-width: 400px;
    margin: 0 auto;
}

.trip-creator > span {
    color: white;
    font-size: 12px;
}

.permission-control-holder {
    max-width: 400px;
    margin: 0 auto;
    padding: 10px;
}

.permission-control-holder > div {
    padding: 5px;
}

.permission-name {
    color: white;
    text-align: right;
    font-size: 12px;
}

.like-message {
    color: rgb(73, 100, 124);
}

.like-button {
    margin-left: 5px;
    font-size: 16px;
}

.like-button:hover {
    cursor: pointer;
    color: white;
}

.like-button-no-cursor {
    margin-left: 5px;
    font-size: 16px;
}

.liked {
    color: white;
}

.unliked {
    color: rgba(255, 255, 255, 0.37);
}

.no-background {
    background-color: transparent;
}

.comment-text-area {
    max-width: 1000px;
    margin: 10px auto;
}

.create-trip-button-group {
    max-width: 700px;
    width: 100%;
}

.text-width {
    width: 650px;
    margin: 10px auto;
}

.comment-dates {
    max-width: 1000px;
    margin: 0 auto;
}

.comment-dates > div {
    margin: 5px;
}

.other-comments {
    margin: 10px auto 10px 0;
    background-color: rgb(133, 193, 200);
}

.my-comments {
    margin: 10px 0 10px auto;
    background-color: rgb(129, 180, 212);
    float: right;
}

.existing-comments {
    width: auto;
    max-width: 600px;
    padding: 5px;
    border: 2px solid rgba(88, 111, 129, 0.31);
    border-radius: 10px;
    text-align: left;
    display: table;
    font-size: 13px;
    margin: 0;
}

.message-wrapper {
    padding: 7px;
    overflow: hidden;
}

.task-holder {
    max-width: 1000px;
    margin: 10px auto;
    display: flex;
}

.task-name {
    width: 80%;

}

.selected-message-wrapper {
    background-color: #7994af;
}

.react-datepicker {
    zoom: 1.1;
}

@media screen and (min-width: 1000px) {
    .react-datepicker {
        font-size: 1.3rem !important;
    }

    .react-datepicker__current-month {
        font-size: 1.5rem !important;
    }

    .react-datepicker__header {
        padding-top: 6px !important;
    }

    .react-datepicker__navigation {
        top: 13px !important;
    }

    .react-datepicker__day-name, .react-datepicker__day {
        margin: 0.5rem !important;
    }

    .react-datepicker__time-list {
        padding: 0;
    }

    .react-datepicker-popper {
        z-index: 99 !important;
        width: 500px;
    }

    .react-datepicker__time-container {
        width: 120px !important;
    }

    .react-datepicker__time-box {
        width: auto !important;
    }

    .react-datepicker-time__header {
        height: 73px;
        font-size: 1.6rem !important;
    }

    .react-datepicker__navigation--next--with-time {
        left: 270px;
    }

    .react-datepicker__month-year-dropdown {
        width: 90% !important;
        left: 5% !important;
    }
}

/** other classes **/
.outside-wrapper {
    display: table;
    width: 100%;
}

.inside-wrapper {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.loading-component-icon {
    font-size: 30px;
    -webkit-animation: rotation 2s infinite linear;
    margin-right: 10px;
}

.loading-component-button {
    -webkit-animation: rotation 2s infinite linear;
    margin-right: 7px;
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

.loading-component-title {
    font-size: 30px;
}

.orange {
    color: #ff6b00;
}

.fa-user {
    padding-right: 5px;
}

.home-page-info {
    border: 15px solid rgba(0, 0, 0, 0.1);
    background-color: rgb(18, 47, 75);
    height: 300px;
}

.trip-selection-section {
    border: 15px solid rgba(0, 0, 0, 0.1);
    background-color: rgb(18, 47, 75);
    height: 150px;
}

.create-trip-section {
    background-color: rgb(116, 163, 220);
    text-align: center;
    padding: 10px;
}

.trip-savvi-instructions {
    max-width: 700px;
    margin: 0 auto;
    padding-bottom: 10px;
    line-height: 1.8em;
}

.trip-savvi-instructions .panel-footer {
    background-color: rgb(18, 47, 75);
}

.trip-savvi-instructions > br {
    margin: 10px;
}

.trip-savvi-instructions .btn-block {
    color: white;
}

.beta-title {
    font-weight: bold;
}

.find-destination-section > div {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 5px;
    border-radius: 5px;
    max-width: 700px;
    margin: 10px auto;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.find-destination-section p {
    color: white;
    font-size: 14px;
    margin: 0 0 5px
}

.find-destination-section > div > .slider {
    width: 500px !important;
    margin-top: 10px;
}

.check-box-options {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.check-box-options > div > .checkbox input[type=checkbox] {
    width: 20px;
    height: 20px;
}

input[type=radio] {
    width: 15px;
    height: 15px;
}

.check-box-options .btn-primary {
    color: white;
    background-color: #337ab7 !important;
}

.check-box-options .btn-primary.active {
    color: white;
    background-color: #286090 !important;
}

.form-check-input > label > span {
    color: white;
    vertical-align: middle;
    font-size: 18px;
    padding: 5px;
}

.navbar-text {
    margin: 5px 0 5px 20px;
    text-decoration: underline;
}

.people-amount-selection {
    margin: 0 auto;
}

.people-amount-selection > div {
    display: inline-block;
    width: 150px;
    padding: 10px;
}

.people-amount-selection > div > label {
    color: white;
    font-size: 13px;
}

.destination-suggestion-section {
    background-color: rgb(116, 163, 220);
    text-align: center;
    padding: 10px;
}

.destination-suggestion-middle {
    min-height: 110px;
    padding: 2px;
}

.destination-suggestion-scroll-panel {
    overflow-x: auto;
    max-width: 100%;
    min-height: 10px;
}

.destination-suggestion-holder {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.destination-itineraries {
    margin: 0 auto;
    display: inline-flex;
}

.accommodation-search-modal .modal-body {
    padding: 5px;
}

.hotel-display-holder {
    margin: 2px 5px 5px;
}

.hotel-display {
    background: #1c2533 no-repeat center;
    background-size: cover;
    width: 440px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
}

.destination-suggestion-small {
    padding: 0;
    width: 650px;
    background: #1c2533 no-repeat center;
    background-size: cover;
    margin: 0 auto;
    border-radius: 7px;
}

.destination-suggestion-planning {
    background: #1c2533 no-repeat center;
    background-size: cover;
    padding: 0;
    width: 400px;
}

.destination-suggestion {
    background: #1c2533 no-repeat center;
    background-size: cover;
    padding: 0;
    width: 550px;
    border-radius: 10px;
    margin: 5px;
}

.destination-suggestion-large {
    background: #1c2533 no-repeat center;
    background-size: cover;
    padding: 0;
    width: 700px;
    margin: 0 auto;
    border-radius: 7px;
    height: 350px;
}

.destination-suggestion-title {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 1px !important;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid transparent;
}

.destination-suggestion-title > span {
    color: white;
    font-size: 22px;
}

.destination-suggestion-title-small {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 2px !important;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid transparent;
}

.destination-suggestion-title-small > span {
    color: white;
    font-size: 16px;
}

.destination-suggestion-selected {
    border: 1px solid rgb(255, 255, 0);
}

.destination-suggestion-selected > .destination-suggestion-title-small, .destination-suggestion-selected > .destination-suggestion-info {
    background: rgba(22, 22, 0, 0.7);
}

.destination-suggestion:hover {
    cursor: pointer;
}

.destination-suggestion > img {
    border-radius: 5px;
}

.destination-suggestion > div {
    padding: 5px;
}

.suggest-new-destination-display {
    margin-bottom: 10px;
}

.destination-suggestion-info {
    background: rgba(0, 0, 0, 0.7);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.btn-sm {
    padding: 2px 5px;
    margin-left: 2px;
}

.destination-suggestion-text {
    display: inline-block;
    vertical-align: middle;
    color: white;
    font-size: 14px;
}

.destination-points-of-interest {
    font-size: 12px;
    color: yellow;
    background: rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 10px;
}

.highlight-detail {
    color: #55bfdb;
}

.highlight-detail-less {
    color: #a7d9dc;
}

.planning-main-page {
    background-color: rgb(116, 163, 220);
    text-align: center;
    padding: 10px;
}

.planning-main-page > h2 {
    color: white;
}

.planning-main-page > div {
    width: 100%;
    padding: 10px;
}

.planning-main-page > div > div {
    height: 100px;
    margin: 0 auto;
    width: 70%;
}

.planning-main-page > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.planning-main-page > div > .flight {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
}

.planning-main-page > div > .hotel {
    background: rgba(0, 0, 0, 0.55);
}

.planning-main-page > div > .extras {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
}

.planning-main-page > div > div > * {
    margin-right: 20px;
}

.flight-picker {
    margin: 0 auto;
    width: 100%
}

.flight-picker-times-location > span {
    font-size: 1.1em;
    padding: 15px;
}

.flight-outbound-details > span {
    font-size: 20px;
    padding: 15px;
}

.flight-inbound-details > span {
    font-size: 20px;
    color: #ebe3ed;
    padding: 15px;
}

.selected-flight {
    background-color: rgba(165, 212, 209, 0.35)
}

.flight-details {
    min-height: 50px;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.flight-details > div {
    height: 100%;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flight-deals {
    text-align: center;
    font-size: 1.0em;
}

.flight-picker-price-per-person {
    font-style: italic;
    padding-left: 5px;
    padding-right: 5px;
}

.flight-airline {
    text-align: center;
    font-size: .8em;
}

.flight-deals-price-from {
    font-size: .6em;
    color: black;
}

.flight-outbound-details > span {
    font-size: 1em;
    color: black;
    padding: 5px 15px;
}

.hotel-name-picker {
    font-size: 12px;
}

.star-ratings {
    margin-left: .5rem
}

.row-flex {
    display: flex
}

.center {
    text-align: center;
    display: block;
    width: 100%;
}

.modal-header {
    padding: 10px;
}

.log-in-modal {
    width: 100%;
}

.log-in-modal > div {
    min-width: 300px;
}

.log-in-modal-button {
    margin: 0 auto;
    display: block;
    width: 300px;
}

.col-h-centered {
    display: flex;
    align-items: center;
}

.col-v-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flight-deals-picker {
    background-color: rgba(125, 203, 219, 0.23);
    margin: 0 auto;
    width: 70%
}

.flight-deals-picker-details {
    background-color: rgb(146, 185, 212);
    height: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.33);
    padding-bottom: 10px;
}

.trip-planning-section > p {
    color: white;
    font-size: 18px;
}

.itinerary-header {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px;
}

.itinerary-header > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.56);
    text-align: left;
}

.itinerary-header > .total-cost {
    font-size: 16px;
    color: white;
}

.itinerary-header > h3 {
    color: white;
    margin: 10px;
}

.itinerary-holder {
    background-color: rgba(140, 170, 177, 0.42);
    width: 100%;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    max-width: 400px;
}

.itinerary-item {
    margin: 0 auto;
    width: 90%;
    min-width: 200px;
    border-radius: 10px;
    padding: 10px;
}

.hotel-item {
    background-color: rgb(191, 209, 212);
}

.flight-item {
    background-color: rgb(186, 212, 203);
}

.extras-item {
    background-color: rgb(172, 204, 212);
}

.itinerary-date {
    margin: 0 auto;
    width: 80%;
    min-width: 200px;
    background-color: rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    padding: 10px;
}

.itinerary-item-flight {
    font-weight: bold;
}

.expired-trip {
    color: #7a000e;
}

.flight-inbound-details > span {
    font-size: 13px;
    color: #ebe3ed;
    padding: 5px 15px;
}

.flight-price {
    font-weight: bold;
    font-size: 14px;
}

.checkbox label {
    color: white;
}

.suggestion-params {
    padding: 5px;
    margin: 10px;
    background: rgba(0, 0, 0, 0.5);
}

.suggestion-params > * > b {
    color: white;
}

.suggestion-form {
    margin-top: 10px;
}

.suggestion-form .form-control {
    color: white;
    background-color: transparent !important;
    margin-left: 10px;
}

#accommodation-cost {
    width: 100px;
}

.new-poll-option-inputs-holder, .todo-list-option-inputs-holder {
    max-width: 800px;
    margin: 0 auto;
}

.new-poll-option-inputs {
    margin: 10px 0;
}

.poll-option-message-component {
    width: 450px;
    height: 25px;
    margin: 2px;
}

.poll-option-message-component > .left {
    float: left;
}

.poll-option-message-component > .middle {
    margin: 0 auto;
}

.poll-option-message-component > .right {
    float: right;
}

.poll-option-message-component > .right > .btn {
    margin-left: 10px;
}

.poll-option-message-component > .right > .checkbox {
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
}

.collapsing {
    height: 97vh;
    -webkit-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    transition-duration: 0.7s;
}

.instructions-text {
    color: white;
    margin-top: 15px;
}

.inline-survey {
    padding: 30px;
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
    background-color: inherit;

    font-size: 1.1em;
}

.inline-survey > div {
    padding: 10px;
}

.inline-survey .form-group {
    margin-bottom: 0;
}

.inline-survey textarea {
    max-width: 500px;
    margin: 10px auto;
}

.inline-survey .fade {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
    opacity: 1;
}

.date-passed-icon {
    color: white;
    margin-left: 5px;
}

#nav-item-notifications .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}

.dropdown-menu {
    padding: 0;
    border: 0;
}

#notification-dropdown .caret {
    display: none;
}

.notification {
    padding: 6px;
    border: 1px solid #334b62;
}

.notification a {
    width: 500px;
    white-space: normal !important;
}

.notification a:hover {
    color: black !important;
}

.notification.unseen {
    background-color: #1c2533;
}

.notification.seen {
    background-color: #3e475a;
}

.notification.unseen a {
    color: white
}

.notification.seen a {
    color: #ababab
}

.notification-badge {
    color: #7199ce
}

.notification-actions {
    width: 100%;
    height: 30px;
    background-color: #2d2d2d;
}

.notification-see-more, .notification-mark-all-read {
    padding: 5px 20px;
    color: white;
    float: left;
    width: 33.3%;
}

.notification-see-more:hover, .notification-mark-all-read:hover {
    cursor: pointer;
    background-color: #555555;
}

.navbar-collapse:before {
    content: none;
}

.navbar-collapse:after {
    content: none;
}

.message-like {
    text-align: right;
    padding-right: 3px;
    padding-top: 3px;
}

.task-done {
    text-decoration: line-through;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein { /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein { /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}